import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  radius: PropTypes.number,
  opacity: PropTypes.number
};

const defaultProps = {
  active: false,
  radius: 40,
  opacity: 1
};

const Select = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

/**
 * styles
 */
const Wrapper = styled.div`
  cursor: pointer;
  font-weight: 600;
  user-select: none;
  font-size: 0.875rem;
  display: inline-block;
  padding: 0.875rem 1rem;
  transition: ease-in-out 0.15s all;
  border-radius: ${({ radius }) => radius / 16}rem;
  color: ${({ active }) => (active ? "#fff" : "var(--bs-black)")};
  background: ${({ active, opacity }) =>
    active ? `rgba(var(--bs-primary-rgb), ${opacity})` : "var(--bs-white)"};
  border: solid 1px
    ${({ active, opacity }) =>
      active ? `rgba(var(--bs-primary-rgb), ${opacity})` : "var(--bs-gray-20)"};
`;

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
