import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const propTypes = {
  /**
   * this will toggle disable on the button
   * @default 'true'
   */
  isValid: PropTypes.bool,

  /**
   * used to determine which variant of button to display
   * @default 'default'
   */
  variant: PropTypes.oneOf(["primary", "secondary", "third", "custom"]),

  /**
   * Will show loading animation when true
   * @default 'false'
   */
  isSubmitting: PropTypes.bool,

  /**
   * Determine whether button is for form submission or normal button
   * @default 'button'
   */
  type: PropTypes.oneOf(["submit", "button"]),

  /**
   * loading props for spinner
   * @default '{color: "#fff"}'
   */
  loader: PropTypes.shape({
    size: PropTypes.oneOf(["sm"])
  })
};

const defaultProps = {
  isValid: true,
  type: "button",
  variant: "primary",
  isSubmitting: false
};

const Template = forwardRef(
  (
    { isSubmitting, children, variant, isValid, loader, value, ...props },
    ref
  ) => {
    switch (variant) {
      case "primary":
        props = {
          ...props,
          color: isValid ? "#fff" : "var(--bs-gray)",
          bg: isValid ? "var(--bs-black)" : "var(--bs-gray-20)",
          border: isValid ? "var(--bs-black)" : "var(--bs-gray-20)"
        };
        break;
      case "secondary":
        props = {
          ...props,
          color: isValid ? "#000" : "var(--bs-gray)",
          border: "var(--bs-gray-20)",
          bg: "#fff"
        };
        break;
      case "third":
        props = {
          ...props,
          color: isValid ? "#000" : "var(--bs-gray)",
          border: "var(--bs-gray-20)",
          bg: "var(--bs-gray-20)"
        };
        break;
      default:
        break;
    }

    return (
      <Wrapper
        ref={ref}
        className="text-truncate"
        $isSubmitting={isSubmitting}
        {...(!isValid && { disabled: true })}
        {...(isSubmitting && { disabled: true })}
        {...{ ...props }}
      >
        {isSubmitting ? <Spinner animation="border" {...loader} /> : children}
      </Wrapper>
    );
  }
);

/**
 * styles
 */
const Wrapper = styled(Button)`
  height: ${({ height }) => (height ? `${height * 0.0625}rem` : "3rem")};
  max-width: ${({ width }) => (width ? `${width * 0.0625}rem` : "100%")};
  ${({ $isSubmitting }) => $isSubmitting && `opacity: 0.8`};
  ${({ radius }) => radius && `border-radius: ${radius};`};
  ${({ border }) => border && `border-color: ${border};`};
  ${({ bg }) => bg && `background-color: ${bg};`};
  ${({ color }) => color && `color: ${color};`};

  ${({ xswidth }) =>
    xswidth &&
    `
    @media(max-width: 768px) {
      width: ${xswidth};
    }
  `}
`;

Template.displayName = "Button";
Template.propTypes = propTypes;
Template.defaultProps = defaultProps;

export default Template;
