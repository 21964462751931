import React, { Fragment } from "react";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

const Error = ({ name, className, withFormik, error, ...props }) => (
  <Fragment>
    {withFormik ? (
      <ErrorMessage name={name} {...props}>
        {(error) => (
          <Fragment>
            {error !== "no-show" && (
              <Small className={className}>{error}</Small>
            )}
          </Fragment>
        )}
      </ErrorMessage>
    ) : (
      <Fragment>
        {error && <Small className={className}>{error}</Small>}
      </Fragment>
    )}
  </Fragment>
);

/**
 * styles
 */
const Small = styled.small`
  display: block;
  margin-left: 1rem;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  color: var(--bs-red);
`;

Error.propTypes = propTypes;

export default Error;
