import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  /**
   * @default '24'
   */
  size: PropTypes.number,

  /**
   * @default '#000'
   */
  color: PropTypes.string,

  /**
   * Set direction of chevron
   *
   * @type {('left'|'right'|'up'|'down')}
   */
  direction: PropTypes.oneOf(["left", "right", "up", "down"])
};

const defaultProps = {
  size: 24,
  color: "#000"
};

const Chevron = ({ size, color, direction, ...props }) => (
  <svg
    width={size}
    fill={color}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {direction === "left" && (
      <path
        d="M10.828 12L15.778 16.95L14.364 18.364L8 12L14.364 5.63599L15.778 7.04999L10.828 12Z"
        fill={color}
      />
    )}

    {direction === "right" && (
      <path
        d="M13.1719 12L8.22192 7.04999L9.63592 5.63599L15.9999 12L9.63592 18.364L8.22192 16.95L13.1719 12Z"
        fill={color}
      />
    )}

    {direction === "up" && (
      <path
        d="M12 10.828L7.04999 15.778L5.63599 14.364L12 8L18.364 14.364L16.95 15.778L12 10.828Z"
        fill={color}
      />
    )}

    {direction === "down" && (
      <path
        d="M12 13.1719L16.95 8.22192L18.364 9.63592L12 15.9999L5.63599 9.63592L7.04999 8.22192L12 13.1719Z"
        fill={color}
      />
    )}
  </svg>
);

Chevron.propTypes = propTypes;
Chevron.defaultProps = defaultProps;

export default Chevron;
