import React, { Fragment } from "react";
import PropTypes from "prop-types";

const propTypes = {
  /**
   * @default '24'
   */
  size: PropTypes.number,

  /**
   * @default '#000'
   */
  color: PropTypes.string,

  /**
   * @default false
   */

  disabled: PropTypes.bool,

  /**
   * Set variant of checkbox
   *
   * @type {('circle'|'square')}
   */
  variant: PropTypes.oneOf(["circle", "square"]),

  /**
   * @default false
   */
  checked: PropTypes.bool.isRequired
};

const defaultProps = {
  size: 24,
  checked: false,
  disabled: false,
  variant: "square",
  color: "var(--bs-gray)",
  checkedColor: "var(--bs-primary)"
};

const Checkbox = ({
  size,
  color,
  variant,
  checked,
  disabled,
  checkedColor,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={checked ? checkedColor : color}
    {...props}
  >
    {variant === "square" && (
      <Fragment>
        {checked ? (
          <Fragment>
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29c-.39.39-1.02.39-1.41 0L5.71 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.58 7.59z" />
          </Fragment>
        ) : (
          <Fragment>
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
          </Fragment>
        )}
      </Fragment>
    )}

    {variant === "circle" && (
      <Fragment>
        {checked ? (
          <Fragment>
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
          </Fragment>
        ) : (
          <Fragment>
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
          </Fragment>
        )}
      </Fragment>
    )}
  </svg>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
